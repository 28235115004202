import { useContext, useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Coach } from "./type"
import { coachs } from "../../../data"
import { Pagination } from "./Pagination"
import { VideoContainer, VideoS, LinkCustom } from "./styles"
import { ContextValue, RootContext } from "../../../pages/Layout/Layout"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../../config"
import { Carousel } from "react-responsive-carousel"
import ArrowImg from "../../../assets/arrow-right-simple.svg"
import SliderSlick from "react-slick"
import styled from "styled-components"
import "slick-carousel/slick/slick.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import "./Slider.style.css"
import { AppContext } from "../../../store"

const SlidersContainerS = styled.div`
  display: flex;
  padding: 25px 0;
  max-width: calc(540px + 150px);

  @media screen and (orientation: landscape) and (max-width: 992px) {
    padding-top: 10px;
  }
`

const CarouselS = styled(Carousel)`
  &.carousel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  @media screen and (orientation: landscape) and (max-width: 992px) {
    &.carousel {
      gap: 0;
    }
  }
`

const SlideS = styled.div`
  min-height: 500px;
  height: 100%;
  padding: 0 20px;

  @media screen and (orientation: landscape) and (max-width: 992px) {
    height: auto;
    min-height: 0px;
  }

  @media (max-width: 768px) {
    min-height: 300px;
  }

  @media (max-width: 350px) {
    min-height: auto;
  }
`

const SecondSectionContainerS = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
`

const MainWrapperS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (orientation: landscape) and (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
  }
`


export const Slider = () => {
  const paginationSliderRef = useRef<SliderSlick>(null)
  const [currentSlide, setCurrentSlide] = useState(coachs[0].id)
  const videoRef = useRef<HTMLDivElement>(null)
  const [isVideoMuted, setIsVideoMuted] = useState(true)
  // const { dispatch } = useContext(RootContext) as ContextValue
  const { dispatch } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate()

  const iterateVideoElements = (videoHandler: (video: HTMLVideoElement) => void) => {
    const wrapper = videoRef.current?.querySelectorAll("video") as unknown as HTMLVideoElement[]

    if (wrapper) {
      for (let i = 1; i < 6; i++) {
        videoHandler(wrapper[i])
      }
    }
  }

  useEffect(() => {
    const handlerWrapper = (video: HTMLVideoElement) => {
      const volumeChangeHandler = () => {
        setIsVideoMuted(video.muted)
      }
      return volumeChangeHandler
    }

    iterateVideoElements((video) => {
      video.addEventListener("volumechange", handlerWrapper(video))
    })

    return () => {
      iterateVideoElements((video) => {
        video.removeEventListener("volumechange", handlerWrapper(video))
      })
    }
  }, [])

  useEffect(() => {
    iterateVideoElements((video) => {
      if (video.nodeName === "VIDEO") {
        if (+video.id === currentSlide) {
          video.play()
        } else {
          video.currentTime = 0
          video.pause()
        }
      }
    })
  }, [currentSlide])

  function handleNextPage(): void {
    if (location.pathname === "/") {
      navigate("/personal-info");
    } else if (location.pathname === "/registration-flow/choose-coach") {
      navigate("/registration-flow/personal-info");
    }
  }

  return (
    <MainWrapperS>
      <SlidersContainerS ref={videoRef}>
        <CarouselS
          selectedItem={currentSlide}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          onChange={(index) => {
            setCurrentSlide(index)
            paginationSliderRef.current?.slickGoTo(index)
          }}
          emulateTouch
          infiniteLoop
          renderArrowNext={(handler) => <div className='swiper-button-next' onClick={handler} />}
          renderArrowPrev={(handler) => <div className={"swiper-button-prev"} onClick={handler} />}
        >
          {coachs.map((coach: Coach, idx) => (
            <SlideS key={coach.id}>
              <VideoContainer id={idx.toString()}>
                <VideoS id={coach.id.toString()} controls playsInline muted={isVideoMuted}>
                  <source src={coach.videoURL} type='video/mp4' />
                </VideoS>
              </VideoContainer>
            </SlideS>
          ))}
        </CarouselS>
      </SlidersContainerS>
      <SecondSectionContainerS>
        <Pagination
          ref={paginationSliderRef}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
        />
        <div className='selection'>
          {coachs[currentSlide].status ? (
            <button
              id='select-btn'
              onClick={() => {
                logEvent(analytics, "select_coach", {
                  name: coachs[currentSlide].coach,
                })
                dispatch({ type: "SET_COACH", payload: coachs[currentSlide].coach })
                handleNextPage()
              }}
            >
              Select {coachs[currentSlide].fullname.split(" ")[0]}
            </button>
          ) : (
            <button id='blocked-btn'>Coming Soon</button>
          )}
        </div>
        <LinkCustom to='/how-it-works'>
          <div>How it works?</div>
          <img src={ArrowImg} alt='arrow' />
        </LinkCustom>
      </SecondSectionContainerS>
    </MainWrapperS>
  )
}
