class AppsflyerController {
  generateOneLinkURL() {
    const oneLinkURL = "https://lifebuddy.onelink.me/N4Of";
    const mediaSource = {defaultValue:"googleads_int"};
    const campaign = {defaultValue:"S01-Stress-Test"};
    const adSet = {defaultValue:"Group1"};
    const googleClickIdKey = "af_sub1";
    const afcid = {paramKey:"af_c_id",defaultValue:"None"};
    // const deepLinkValue = { defaultValue:"%7B%22name%22:%22Volodymyr%22,%22age%22:%2244%22,%22coach%22:%22luna%22,%22gender%22:%22male%22,%22topics%22:%5B%22Stress,%20Anxiety,%20and%20Overwhelm%22,%22Procrastination%20and%20Time%20Management%22%5D%7D"};
    const customssui = {paramKey:"af_ss_ui",defaultValue:"true"};

    // Call the function after embedding the code through a global parameter on the window object called window.AF_SMART_SCRIPT.
    // Onelink URL is generated.
    if ((window as any).AF_SMART_SCRIPT) {
        const result = (window as any).AF_SMART_SCRIPT.generateOneLinkURL({
            oneLinkURL: oneLinkURL,
            afParameters: {
                mediaSource: mediaSource,
                campaign: campaign,
                adSet: adSet,
                googleClickIdKey: googleClickIdKey,
                afCustom: [
                    afcid,
                    customssui
                ],
        //      deepLinkValue: deepLinkValue
            }
        });

        console.log(result.clickURL);
        return result.clickURL;
    }
    return false;
  }

  getOneLinkURL(deeplink: string, onelink: string) {
    if (onelink !== "") {
        return `${onelink}&deep_link_value=${deeplink}`
    } else {
        return `https://lifebuddy.onelink.me/N4Of?deep_link_value=${deeplink}`
    }
  }
}

export const appsflyerController = new AppsflyerController;