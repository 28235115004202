import React from "react"
import ReactDOM from "react-dom/client"
import { RouterProvider } from "react-router-dom"
import { router } from "./router"
import GlobalStyles from "./assets/styles/global.styles"
import { AppContextProvider } from "./store"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <RouterProvider router={router} />
      <GlobalStyles />
    </AppContextProvider>
  </React.StrictMode>,
)
