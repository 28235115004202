import { createContext, useReducer, useEffect } from "react";
import { Action } from "../types";

export interface AppState {
  name: string,
  age: string,
  gender: string,
  coach: string,
  topics: string[],
  device: any,
  queryParams: string,
  onelink: string,
}

export const initialState: AppState = {
  name: "",
  age: "",
  gender: "",
  coach: "",
  topics: [],
  device: {},
  queryParams: "",
  onelink: "",
}

interface AppContextType {
  state: AppState;
  dispatch: React.Dispatch<Action>;
}

export const AppContext = createContext<AppContextType>({
  state: initialState,
  dispatch: () => undefined,
});

const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return {
        ...state,
        name: action.payload.name,
        age: action.payload.age,
        gender: action.payload.gender,
      }
    case "SET_COACH":
      return {
        ...state,
        coach: action.payload,
      }
    case "SET_QUERY_PARAMS":
      return {
        ...state,
        queryParams: action.payload,
      }
    case "SET_TOPICS":
      return {
        ...state,
        topics: action.payload,
      }
    case "SET_ONELINK":
      return {
        ...state,
        onelink: action.payload,
      }
    default:
      return state
  }
}

const loadStateFromLocalStorage = () => {
  const storedState = localStorage.getItem("appState");
  return storedState ? JSON.parse(storedState) : initialState;
};

// eslint-disable-next-line react/prop-types
export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, loadStateFromLocalStorage());

  useEffect(() => {
    localStorage.setItem("appState", JSON.stringify(state));
  }, [state]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
