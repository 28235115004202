import { OnBoardingLayout } from "../../components"
import { useSendPageView } from "../../hooks"
import image from "../../assets/on-boarding-img-1.png";
import { appsflyerController } from "../../api"
import { useContext, useEffect } from "react";
import { AppContext } from "../../store";

export const OnBoardingStart = () => { 
  useSendPageView({ title: "OnBoardingStart" })
  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    const onelink = appsflyerController.generateOneLinkURL();
    if (onelink) {
      dispatch({ type: "SET_ONELINK", payload: onelink });
    }
  }, [])

  const pageData = [
    {
      header: "Breakthrough AI Personalized Coaching and Personality Analysis for Maximum Impact",
      content: "AI possesses unparalleled analytical and diagnostic abilities to design the best approach and personalize it to your needs for most effective transformation.",
    },
    {
      header: "Fast Results Guaranteed or Your Money Back",
      content: "1-month or 3-month programs, with up to 10 sessions per month are starting just under $20/month. No results? Money back no questions asked.",
    },
    {
      header: "Get Started with a Free Session - No Credit Card Needed",
      content: "Get coupon code for free introductory session after registration.",
    },
  ];

  return (
    <>
      <OnBoardingLayout
        image={image}
        data={pageData}
        backgroundColor="#fcf3f1"
        buttonText="Start Your Journey"
        buttonLink="issues-questionnaire"
        backgroundImagePosition="right"
      ></OnBoardingLayout>
    </>
  )
}
