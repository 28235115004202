import styled from "styled-components"


export const LayoutS = styled.div`
  background: #DCE6ED;
  color: #14141B;  
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .form {
    gap: 0;
  }

  MobileHeader {
    text-align: left !important;
  }
`

export const IssueChoiceWrapperS = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 580px;
  width: 100%;

  .subheader {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 10px 0;
  }
`

export const IssueChoiceS = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px;
  font-height: 24px;
  font-weight: 400;

  .header-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .header {
      font-size: 16px;
      font-weight: 600;
    }

    img {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }
`

export const ButtonWrapS = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #DCE6ED;
  padding: 30px 0;
`

export const ErrorMessage = styled.p`
  color: #d32f2f;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  margin-left: 14px;
  position: absolute;
  bottom: -26px;
`
