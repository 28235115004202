import styled from "styled-components"
import { coachs } from "../../../data"
import "./Slider.style.css"
import Slider, { Settings } from "react-slick"
import { Dispatch, forwardRef, SetStateAction, useEffect, useState } from "react"

const SliderS = styled(Slider)`
  max-width: calc(540px + 60px);
  width: 100%;

  .slick-slide img {
    margin: auto;
  }

  @media screen and (orientation: landscape) and (max-width: 992px) {
    max-width: 330px;
  }

  @media (max-width: 635px) {
    max-width: calc(100% - 45px);
  }
`

const paginationSliderSettings: Settings = {
  infinite: true,
  speed: 400,
  slidesToScroll: 5,
  centerMode: true,
  focusOnSelect: true,
  centerPadding: "0"}

export const Pagination = forwardRef<
  Slider,
  {
    currentSlide: number
    setCurrentSlide: Dispatch<SetStateAction<number>>
  }
>(({ currentSlide, setCurrentSlide }, ref) => {
  const [slidesPerView, setSlidesPerView] = useState<number>(coachs.length)

  useEffect(() => {
    function handleResizeSlidesPerView() {
      const windowWidth = window.innerWidth
      if (windowWidth <= 635 || (window.matchMedia("(orientation: landscape)").matches && windowWidth <= 992)) {
        return 3
      } else {
        return 5
      }
    }
    setSlidesPerView(handleResizeSlidesPerView())

    const handleResize = () => {
      setSlidesPerView(handleResizeSlidesPerView)
    }

    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <SliderS
      ref={ref}
      {...paginationSliderSettings}
      slidesToShow={slidesPerView}
      arrows={false}
      swipe
    >
      {coachs.map((coach) => (
        <div
          key={coach.id}
          className={`pagination-card  ${currentSlide === coach.id && "active"}`}
          onClick={() => setCurrentSlide(coach.id)}
        >
          <div className='img-small'>
            <img src={coach.img} alt={coach.fullname} />
          </div>
        </div>
      ))}
    </SliderS>
  )
})


Pagination.displayName = "Pagination"