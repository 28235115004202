import { createBrowserRouter } from "react-router-dom"
import {
  Home,
  Layout,
  NotFound,
  PersonalInfo,
  PrivacyPolicy,
  SignUp,
  SignUpWithEmail,
  Success,
  Terms,
  HowItWorks,
  Introduction,
  Faq,
  ContactUs,
  OnBoardingStart,
  OnBoardingComplete,
  IssuesQuestionnaire
} from "../pages"
import { Redirect } from "../components"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "personal-info",
        element: <PersonalInfo />,
      },
      // {
      //   path: "sign-up",
      //   element: <SignUp />,
      // },
      // {
      //   path: "sign-up-with-email",
      //   element: <SignUpWithEmail />,
      // },
      {
        path: "success",
        element: <Success />,
      },
      {
        path: "terms-of-services",
        element: <Terms />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "how-it-works",
        element: <HowItWorks />,
      },
      {
        path: "introduction",
        element: <Introduction />,
      },
      {
        path: "q&a",
        element: <Faq />,
      },
      {
        path: "contact-us",
        element: <ContactUs />,
      },
      {
        path: "link/:page/:payload",
        element: <Redirect />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "registration-flow/",
    children: [
      {
        index: true,
        element: <OnBoardingStart />
      },
      {
        path: "issues-questionnaire",
        element: <IssuesQuestionnaire />
      },
      {
        path: "choose-coach",
        element: <Layout />,
        children: [
          {
            index: true,
            element: <Home />
          }
        ]
      },
      {
        path: "personal-info",
        element: <Layout />,
        children: [
          {
            index: true,
            element: <PersonalInfo />
          }
        ]
      },
      {
        path: "on-boarding-complete",
        element: <OnBoardingComplete />
      },
    ]
  }
])
