import { FC } from "react"
import header from "../../../assets/logo.svg"
import { OnBoardingHeaderS } from "./styles"

export const OnBoardingHeader: FC = () => {
  return (
    <OnBoardingHeaderS>
      <img src={header}/>
      <div className={"texts"}>
        <div className={"company-name"}>LifeBuddy.ai</div>
        <div>AI Life-Coaching For The Best You</div>
      </div>
    </OnBoardingHeaderS>
  )
}
