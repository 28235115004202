import styled from "styled-components"

export const OnBoardingHeaderS = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 11px;

  img {
    height: 60px;
  }

  .texts {
    color: rgba(27, 46, 58, 1);
    font-size: 11px;
    font-weight: 600;

    .company-name {
      color: rgba(78, 132, 166, 1);
      font-size: 24px;
    }
  }
`