import styled from "styled-components"
import CurlyImgLeft from "../../../assets/curly-line-left.svg"
import CurlyImgRight from "../../../assets/curly-line-right.svg"
import { BackgroundImagePosition } from "./types"


export const OnBoardingWrap = styled.div<{
  $backgroundColor: string;
  $backgroundImagePosition: BackgroundImagePosition;
}>`
  display: flex;
  flex-direction: column;
  background-color: ${({$backgroundColor}) => $backgroundColor};
  min-height: 100vh;
  flex: 1;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  z-index: 1;

  .width-wrap {
    max-width: 540px;
    width: 100%;
    position: relative;
  }
  
  .bg {
    position: absolute;
    width: ${({$backgroundImagePosition}) => $backgroundImagePosition === "left" ? "219px" : "179px"};
    height: ${({$backgroundImagePosition}) => $backgroundImagePosition === "left" ? "104px" : "142px"};
    bottom: 20px;
    right: ${({$backgroundImagePosition}) => $backgroundImagePosition === "left" ? "auto" : 0};
    left: ${({$backgroundImagePosition}) => $backgroundImagePosition === "left" ? 0 : "auto"};
    z-index: -1;
    background-image: url(${({$backgroundImagePosition}) => $backgroundImagePosition === "left" ? CurlyImgLeft : CurlyImgRight});
    background-repeat: no-repeat;
    background-position: bottom -5px ${({$backgroundImagePosition}) => $backgroundImagePosition};
  }

  @media (max-width: 576px) {
    background-image: url(${({$backgroundImagePosition}) => $backgroundImagePosition === "left" ? CurlyImgLeft : CurlyImgRight});
    background-repeat: no-repeat;
    background-position: bottom 30px ${({$backgroundImagePosition}) => $backgroundImagePosition};

    .bg {
      display: none;
    }
  }
`

export const OnBoardingS = styled.div<{
  $image: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  background-image: url(${({$image}) => $image});
  background-size: auto 140px;
  background-repeat: no-repeat;
  background-position: center 5px;
  padding-top: 110px;

  .text {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    line-height: 24px;

    &.text-header { 
      background-color: rgba(67, 34, 34, 1);
      color: rgba(245, 245, 246, 1);
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &.text-content {
      padding: 8px;
      color: rgba(20, 20, 27, 1);
      font-size: 16px;
      font-weight: 400;
    }
  }
`