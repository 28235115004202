import { FC } from "react"
import { OnBoardingWrap, OnBoardingS } from "./styles"
import { Props } from "./types"
import { OnBoardingHeader } from "../OnBoardingHeader/OnBoardingHeader"
import { Button } from "../../../components"
import { useNavigate } from "react-router-dom"

export const OnBoardingLayout: FC<Props> = ({
  image,
  data,
  backgroundColor,
  buttonText,
  buttonLink,
  backgroundImagePosition
}) => {
  const navigate = useNavigate()
  
  return (
    <OnBoardingWrap
      $backgroundColor={backgroundColor}
      $backgroundImagePosition={backgroundImagePosition}
    >
      <div>
        <OnBoardingHeader></OnBoardingHeader>
        <OnBoardingS
          $image={image}
          className={"width-wrap"}
        >
          {data.map((el, index) => (
            <div key={index}>
              <div className={"text text-header"}>{el.header}</div>
              <div className={"text text-content"}>{el.content}</div>
            </div>
          ))}
        </OnBoardingS>
      </div>
      <div className="width-wrap">
        <div className="bg"></div> 
        <Button 
          onClick={() => navigate(buttonLink)}
          width="large"
        >
          {buttonText}
        </Button>
      </div>
    </OnBoardingWrap>
  )
}
